@keyframes highlightAnimation {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.imgHighlightStyling  {
    animation-name: highlightAnimation;
    animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
